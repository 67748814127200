<template>
  <div v-if="config">
    <v-row
      v-for="item of requests"
      :key="item.ID"
      align="center"
      justify="center"
      :style="{ color: config.ColorContent, borderBottom: `1px solid ${config.ColorContent}` }"
      style="margin:0;"
    >
      <v-col :cols="3" style="text-align: center;">
        <v-img 
          v-if="item.Image"
          :src="item.Image"
          style="width:100%;height: auto;"
        />
      </v-col>
      <v-col :cols="6" style="text-align: left;padding: 10px 0;">
        <p style="line-height:1;margin:0;">
          {{ getTranslation(item.Name, locale) }}
        </p>
        <small v-if="item.Description">
          {{ getTranslation(item.Description, locale) }}
        </small>
      </v-col>
      <v-col :cols="3" style="padding:10px;vertical-align:middle;">
        <v-btn
          :color="config.ColorContent"
          dark
          style
          small
          :style="{ color: config.BGColorContent }"
          @click="handleSelectedItem(item.ID)"
        >
          {{$t('dtouch.request.request', locale)}}
        </v-btn>
      </v-col>
    </v-row>
    <confirm-form
      :workspaceID="workspaceID"
      :locale="locale"
      :config="config"
      :selectedID="selectedID"
      :onClose="handleClose"
      :onSave="handleSave"
    />
  </div>
</template>
<script>
import apiDtouch from '@/services/apiDtouch'
import utils from '@/services/utils'
import ConfirmForm from './ConfirmForm'
export default {
  name:'Item53Issues',
  components: {
    ConfirmForm,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    requests: [],
    selectedID: null,
  }),
  computed: {
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
      //return utils.getWorkspaceIdentification ()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetData () {
      apiDtouch.getAll (`v2/public/workspaces/${this.workspaceID}/issues-manager`)
        .then(response => {
          if (!response) return
          this.requests = response.map(item => {
            return item
          })
        })
    },
    handleSelectedItem (v) {
      this.selectedID = v
    },
    handleClose () {
      this.selectedID = null
    },
    handleSave (v) {
      apiDtouch.addItem (`v2/public/issues`, v)
        .then(response => {
          if (!response) return
          if (response.data.status === 'OK') {
            alert(this.$t('dtouch.request.messages.ok', this.locale))
            this.handleClose()
          } else alert(this.$t(`dtouch.request.messages.${response.data.message}`, this.locale))
        })
        .catch(() => {
          alert ('ERROR')
        })
    },
  },
}
</script>


